<template>
  <div>
    <vs-popup title="Proses Closing" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Proyek *</label>
            <vs-select autocomplete v-model="data.id_proyek" class="w-full">
              <vs-select-item :value="null" v-if="$can('select_all_proyek_closing')" text="Semua Proyek"/>
              <vs-select-item v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="it.kode + ' - ' + it.nama"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Tgl Closing *</label>
            <flat-pickr class="w-full" v-model="data.closing_date"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-input class="w-full" v-model="data.description"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="checkUnpostedTransactions">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import ClosingRepository from '@/repositories/accounting/closing-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import moment from 'moment/moment'
import ProyekRepository from '@/repositories/master/proyek-repository'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'

export default {
  name: 'ClosingAdd',
  components: { ValidationErrors, flatPickr },
  props: ['isActive'],
  mounted () {
    this.getAllProyek()
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      data: {
        id_proyek: null,
        closing_date: moment().format('YYYY-MM-DD'),
        description: null
      },
      proyeks: []
    }
  },
  methods: {
    checkUnpostedTransactions () {
      this.errors = null
      this.isLoading = true

      const data = {
        id_proyek: this.data.id_proyek,
        closing_date: this.data.closing_date
      }
      ClosingRepository.checkUnpostedTransactions(data)
        .then(response => {
          const unpostedCount = response.data.data.unposted_count
          if (unpostedCount > 0) {
            this.confirmClosing(unpostedCount)
            this.isLoading = false
          } else {
            this.save()
          }
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      ClosingRepository.close(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    confirmClosing (unpostedCount) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: `Terdapat ${unpostedCount} transaksi yang belum diposting, yakin ingin proses closing?`,
        acceptText: 'Ya, Lanjutkan',
        cancelText: 'Batal',
        accept: () => { this.save() }
      })
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
